import './App.css';


import sj_soap_logo from './resources/sj_soap_logo.png'
import instagram_logo from './resources/ig-logo-black.svg';

import notice_icon from "./resources/outline_campaign_black_18dp.png"
import banner02 from "./resources/banner_20_12_27.png"

import React, {Component} from "react";
import Button from "@material/react-button"

function App() {
    return (
        <div className="App">
            <Top/>
            <NavigationSection/>
            <BannerSection/>
            <ContentsSection/>
            <Footer/>
        </div>
    );
}

function Top() {
    return (
        <div className="header">
            <header className="App-header">
                <img src={sj_soap_logo} className="App-logo" alt="logo"/>
            </header>
        </div>
    )
}

function NavigationSection() {
    return (
        <div className="navigation-section">
            {/*<p className="nav-item">공지사항</p>*/}
            {/*<p className="nav-item">커뮤니티</p>*/}

            <p className="nav-link">
                <a href="https://www.instagram.com/soojoo__soap/">
                    <img src={instagram_logo}/>
                </a>
            </p>
        </div>
    )
}

function BannerSection() {
    return (
        <div className="banner-section">
            <Notice/>
            <Banner/>
            {/*<Booking/>*/}
        </div>
    )
}

function Banner() {
    return (
        <div className="banner">
            <BannerItem
                bannerText="OPEN EVENT"
            />
            <BannerItem
                primary={true}
                bannerText="예약하기"
                clickEvent={
                    function () {
                        window.open("https://smartstore.naver.com/soojoosoap/products/5302219521")
                    }
                }
            />
            <BannerItem
                // bannerText="예약하기"
                imageUrl={banner02}
            />
        </div>
    )
}

class BannerItem extends Component {
    render() {
        const isPrimary = this.props.primary != null ? this.props.primary : false
        let className;

        if (isPrimary) {
            className = "banner-item banner-item-primary"
        } else {
            className = "banner-item banner-item-secondary"
        }

        if (this.props.bannerText != null) {
            return (
                <div className={className} onClick={this.props.clickEvent}>
                    <p>{this.props.bannerText}</p>
                </div>
            )
        } else if (this.props.imageUrl != null) {
            return (
                <div className={className} onClick={this.props.clickEvent}>
                    <img src={this.props.imageUrl}/>
                </div>
            )
        } else {
            return <div className="banner-invisible"></div>
        }
    }
}

class Notice extends Component {
    render() {
        return (
            <div className="notice">
                <NoticeItem
                    notice={"2021년 1월 13일 부터 오픈 예정입니다."}
                />
                <NoticeItem
                    notice={"Will open 21.01.13"}
                />
            </div>
        )
    }
}

class NoticeItem extends Component {
    render() {
        return (
            <div className="notice-item">
                {/*<img className="notice-icon" src={notice_icon}/>*/}
                <img className="notice-icon"/>
                <p className="notice-contents">{this.props.notice}</p>
            </div>
        )
    }
}

function Booking() {
    return <div className="booking mdc-icon-button">
        <a href="https://www.naver.com">
            <span>예약하기</span>
        </a>
    </div>
}

function ContentsSection() {
    return <div className="contents-section">
        <span>
            안녕하세요.<br/>
            수주숲은 현직 화장품 연구원이 꾸려가는 공간입니다.<br/><br/>
            사람마다 자신에게 맞는 화장품 성분은 따로 있습니다.<br/><br/>
            수주숲에서는 화장품에 사용되는 다양한 원료를 오감으로 즐기며
                        나에게 맞는 원료르 찾을 수 있습니다.<br/>
            이 원료를 이용하여 세상에 하나뿐인 나만의 화장품을 만들 수 있습니다.<br/><br/>
            수주숲과 함께 소중한 내 피부를 지켜보지 않으실래요?<br/>
        </span>
    </div>
}

function Footer() {
    return (
        <div className="footer">
            <p className="footer-span">
                대표 : 신정선
            </p>
        </div>
    )
}

export default App;
